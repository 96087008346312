exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-abalone-js": () => import("./../../../src/pages/products/abalone.js" /* webpackChunkName: "component---src-pages-products-abalone-js" */),
  "component---src-pages-products-ballon-js": () => import("./../../../src/pages/products/ballon.js" /* webpackChunkName: "component---src-pages-products-ballon-js" */),
  "component---src-pages-products-basket-js": () => import("./../../../src/pages/products/basket.js" /* webpackChunkName: "component---src-pages-products-basket-js" */),
  "component---src-pages-products-cordyceps-js": () => import("./../../../src/pages/products/cordyceps.js" /* webpackChunkName: "component---src-pages-products-cordyceps-js" */),
  "component---src-pages-products-cucumber-js": () => import("./../../../src/pages/products/cucumber.js" /* webpackChunkName: "component---src-pages-products-cucumber-js" */),
  "component---src-pages-products-fins-js": () => import("./../../../src/pages/products/fins.js" /* webpackChunkName: "component---src-pages-products-fins-js" */),
  "component---src-pages-products-ginseng-js": () => import("./../../../src/pages/products/ginseng.js" /* webpackChunkName: "component---src-pages-products-ginseng-js" */),
  "component---src-pages-products-herbs-js": () => import("./../../../src/pages/products/herbs.js" /* webpackChunkName: "component---src-pages-products-herbs-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-nest-js": () => import("./../../../src/pages/products/nest.js" /* webpackChunkName: "component---src-pages-products-nest-js" */),
  "component---src-pages-products-product-page-template-js": () => import("./../../../src/pages/products/product_page_template.js" /* webpackChunkName: "component---src-pages-products-product-page-template-js" */),
  "component---src-pages-products-soup-js": () => import("./../../../src/pages/products/soup.js" /* webpackChunkName: "component---src-pages-products-soup-js" */),
  "component---src-pages-products-watered-cucumber-js": () => import("./../../../src/pages/products/watered_cucumber.js" /* webpackChunkName: "component---src-pages-products-watered-cucumber-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

